import React from "react";
import PageScripts from "./PageScripts";
import { ComponentRenderHooks } from "@sc/plugins/index.d";

export const onComponentRender = (hook, { trackingPayload }) => {
  if (hook.id === ComponentRenderHooks.PAGE_LOAD && trackingPayload.length)
    return [<PageScripts key="page_scripts" payload={trackingPayload} />];
};

export default PageScripts;
