// const { get } = require("lodash");

const evalScript = elem => {
  var data = elem.text || elem.textContent || elem.innerHTML || "",
    head = document.getElementsByTagName("head")[0] || document.documentElement,
    script = document.createElement("script");

  script.type = "text/javascript";
  try {
    // doesn't work on ie...
    script.appendChild(document.createTextNode(data));
  } catch (e) {
    // IE has funky script nodes
    script.text = data;
  }

  head.insertBefore(script, head.firstChild);
  head.removeChild(script);
};

export const triggerScripts = section => {
  const nodeName = (elem, name) =>
    elem.nodeName && elem.nodeName.toUpperCase() === name.toUpperCase();

  let scripts = [],
    children_nodes = section.childNodes,
    i,
    child;

  for (i = 0; children_nodes[i]; i++) {
    child = children_nodes[i];
    if (
      nodeName(child, "script") &&
      (!child.type || child.type.toLowerCase() === "text/javascript")
    ) {
      scripts.push(child);
    }
  }

  for (i = 0; scripts[i]; i++) evalScript(scripts[i]);
};