import React from "react";
import { get } from "lodash";
import { triggerScripts } from "./scripts";
import { PageScriptsProps } from "./index.d";

export const PageScripts: React.FC<PageScriptsProps> = ({
  payload = {},
  alreadyParsed = false,
}) => {
  React.useEffect(() => {
    const parsedTrackingPayload = alreadyParsed
      ? payload
      : JSON.parse(unescape(payload));
    console.log({ parsedTrackingPayload });

    const renderHead: any = document.getElementById("renderHead");
    const renderPreBody: any = document.getElementById("renderPreBody");
    const renderPostBody: any = document.getElementById("renderPostBody");

    // why not use react helmet??
    if (renderHead) {
      renderHead.innerHTML = get(parsedTrackingPayload, "head", "");
      triggerScripts(renderHead);
    }

    if (renderPreBody) {
      renderPreBody.innerHTML = get(parsedTrackingPayload, "body", "");
      triggerScripts(renderPreBody);
    }

    if (renderPostBody) {
      renderPostBody.innerHTML = get(parsedTrackingPayload, "bodyEnd", "");
      triggerScripts(renderPostBody);
    }
  }, []);
  return null;
};

export default PageScripts;
